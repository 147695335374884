import React, { useState } from 'react';
// import ReactDOM from 'react-dom';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import DefaultLayout from '../../layouts/DefaultLayout';
import Seo from '../../../app/Seo';
import Page from '../../components/Page';
import Iframe from '../../components/Iframe';
import theme from '../../themes/light';

const LegalCookiePolicyPage = () => {
  const [iFrameHeight, setiFrameHeight] = useState('500px');

  const handleOnLoad = (arg) => {
    // console.log(`handleOnLoad, arg`, arg);
    // console.log(`arg.target`, arg.target);
    // console.log(`XXX`, arg.target.contentWindow);
    // const obj: any = ReactDOM.findDOMNode(arg.target);
    // console.log(`obj`, obj.contentWindow.document);
    // console.log(`handleOnLoad, this`, this);
    // (function (o: any) {
    //   if (o) o.style.height = o.contentWindow.document.body.scrollHeight + 'px';
    // })(this);
  };

  return (
    <>
      <Seo title='Cookie Policy' />
      <DefaultLayout>
        <ThemeProvider theme={theme}>
          <Paper>
            <Container>
              <Typography
                variant='h3'
                component='h1'
                align='center'
                gutterBottom={true}
              >
                Our Cookie Policy
              </Typography>
              {/* <iframe
                style={{
                  maxWidth: 640,
                  width: '100%',
                  height: iFrameHeight,
                  overflow: 'visible',
                }}
                onLoad={() => {
                  console.log(`onLoad`);
                  // const obj: any = ReactDOM.findDOMNode(this);
                  // if (obj) {
                  //   setiFrameHeight(
                  //     obj.contentWindow.document.body.scrollHeight + 'px'
                  //   );
                  // }
                }}
                ref='iframe'
                src='https://app.termly.io/document/cookie-policy/6ffa152f-cfb8-49ae-9886-3966178417a0'
                width='100%'
                height={iFrameHeight}
                scrolling='no'
                frameBorder='0'
              />
 */}
              {/* <div
                style={{ margin: '0px', padding: '0px', overflow: 'hidden' }}
              >
        
                <iframe
                  src='https://app.termly.io/document/cookie-policy/6ffa152f-cfb8-49ae-9886-3966178417a0'
                  frameborder='0'
                  style={{ overflow: 'hidden', height: '100%', width: '100%' }}
                  height='100%'
                  width='100%'
                ></iframe>
              </div> */}

              <Iframe
                url='https://app.termly.io/document/cookie-policy/6ffa152f-cfb8-49ae-9886-3966178417a0'
                onLoad={(arg) => handleOnLoad(arg)}
                // position='absolute'
                // width='100%'
                id='myIframe'
                // className='myClassname'
                // height='100vh'
                // styles={{ height: '500px' }}
                // ref='iframe'
                width='100%'
                height={iFrameHeight}
                scrolling='yes'
              />
            </Container>
          </Paper>
        </ThemeProvider>
      </DefaultLayout>
    </>
  );
};

export default LegalCookiePolicyPage;
